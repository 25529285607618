export enum EnvVars {
  BUILD_ID = "BuildId",
  DEBUG = "DEBUG",
  NEXT_PUBLIC_LOCAL_DEV = "NEXT_PUBLIC_LOCAL_DEV",
  AZURE_CONFIGURATION_CONNECTION_STRING = "AZURE_CONFIGURATION_CONNECTION_STRING",

  MINIFY_JS_ENABLED = "MINIFY_JS_ENABLED",
  MINIFY_CSS_ENABLED = "MINIFY_CSS_ENABLED",
  TEST_IDS_ENABLED = "TEST_IDS_ENABLED",

  APP_INSIGHTS_CONNECTION_STRING = "APPINSIGHTS_CONNECTION_STRING",

  NEXT_PUBLIC_LOCAL_FRONT_END_BASE_URL = "NEXT_PUBLIC_LOCAL_FRONT_END_BASE_URL",
  NEXT_PUBLIC_LOCAL_CLIENT_API_BASE_URL = "NEXT_PUBLIC_LOCAL_CLIENT_API_BASE_URL",
  LOCAL_SERVER_SIDE_API_BASE_URL = "LOCAL_SERVER_SIDE_API_BASE_URL",
}
