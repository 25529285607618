export enum LOCALES {
  "en-gb" = "en-gb",
  "fr-be" = "fr-be",
  "nl-be" = "nl-be",
  "cs-cz" = "cs-cz",
  "de-de" = "de-de",
  "es-es" = "es-es",
  "fr-fr" = "fr-fr",
  "en-ie" = "en-ie",
  "it-it" = "it-it",
  "nl-nl" = "nl-nl",
  "pl-pl" = "pl-pl",
  "pt-pt" = "pt-pt",
  "sl-si" = "sl-si",
  "en-us" = "en-us",
  "es-us" = "es-us",
  "de-at" = "de-at",
  "ro-ro" = "ro-ro",
  "sk-sk" = "sk-sk",
  "hu-hu" = "hu-hu",
  "el-gr" = "el-gr",
}

export enum CURRENCY_CODES {
  GBP = "GBP",
  EUR = "EUR",
  CZK = "CZK",
  PLN = "PLN",
  USD = "USD",
  RON = "RON",
  HUF = "HUF",
}

export enum LOGO_PATHS {
  PRIMARK = "/assets/images/logo.svg",
  PENNEYS = "/assets/images/logo-en-ie.png",
}

export const DEFAULT_LANGUAGE_CODE = "en";
export const DEFAULT_COUNTRY_CODE = "en";
export const DEFAULT_LOCALE = LOCALES["en-gb"];
export const UNKNOWN_LOCALE = "unknown";
export const CURRENCY_CODE = CURRENCY_CODES.GBP;

export const normalizeLocale = (locale: LOCALES): string => {
  return LOCALES[locale];
};

export const getLogoPathByLocale = (locale: string): LOGO_PATHS => {
  switch (locale) {
    case LOCALES["en-ie"]:
      return LOGO_PATHS.PENNEYS;
    default:
      return LOGO_PATHS.PRIMARK;
  }
};

export const getCurrencyCodeByLocale = (locale: string): CURRENCY_CODES => {
  switch (locale) {
    case LOCALES["en-gb"]: {
      return CURRENCY_CODES.GBP;
    }
    case LOCALES["fr-be"]:
    case LOCALES["nl-be"]:
    case LOCALES["de-de"]:
    case LOCALES["es-es"]:
    case LOCALES["fr-fr"]:
    case LOCALES["en-ie"]:
    case LOCALES["it-it"]:
    case LOCALES["nl-nl"]:
    case LOCALES["pt-pt"]:
    case LOCALES["sl-si"]:
    case LOCALES["de-at"]:
    case LOCALES["sk-sk"]:
    case LOCALES["el-gr"]: {
      return CURRENCY_CODES.EUR;
    }
    case LOCALES["cs-cz"]: {
      return CURRENCY_CODES.CZK;
    }
    case LOCALES["pl-pl"]: {
      return CURRENCY_CODES.PLN;
    }
    case LOCALES["en-us"]:
    case LOCALES["es-us"]: {
      return CURRENCY_CODES.USD;
    }
    case LOCALES["ro-ro"]: {
      return CURRENCY_CODES.RON;
    }
    case LOCALES["hu-hu"]: {
      return CURRENCY_CODES.HUF;
    }
    default:
      return CURRENCY_CODE;
  }
};

export const getCountryCodeByLocale = (locale: string): string => {
  return locale.split("-")?.[1]?.toUpperCase();
};
