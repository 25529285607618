import { cloneDeep } from "lodash";

import {
  CLIENT_STORAGE_CONTAINER_ELEMENT_NAME,
  CLIENT_STORAGE_NAME,
  DEFAULT_STORAGE_VALUE,
  LOGGER_CONTEXT as LOGGER_CONTEXT_PREFIX,
} from "./constant";
import { SERVER_STORAGE } from "./server-storage";
import { ConfigStorage } from "./types";

const LOGGER_CONTEXT = `${LOGGER_CONTEXT_PREFIX}:config-storage`;

/* eslint-disable no-restricted-globals */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getConfig(): ConfigStorage {
  const LOGGER_CONTEXT_LOCAL = `${LOGGER_CONTEXT}:getData`;

  if (!IS_SERVER && !IS_TEST) {
    if (window[CLIENT_STORAGE_NAME]) {
      return window[CLIENT_STORAGE_NAME];
    }

    window[CLIENT_STORAGE_NAME] = cloneDeep(DEFAULT_STORAGE_VALUE);

    const clientStorageContainerElement = window.document.getElementById(
      CLIENT_STORAGE_CONTAINER_ELEMENT_NAME,
    );

    if (clientStorageContainerElement) {
      if (clientStorageContainerElement.textContent) {
        try {
          window[CLIENT_STORAGE_NAME] = JSON.parse(
            clientStorageContainerElement.textContent,
          );
        } catch (e: unknown) {
          throw new Error(
            `${LOGGER_CONTEXT_LOCAL}: error occurred while parsing client storage container HTML element contents on the client: ${e}`,
          );
        }
      }
    }

    const clientStorage = window[CLIENT_STORAGE_NAME];

    return clientStorage;
  }

  return SERVER_STORAGE;
}
/* eslint-enable no-restricted-globals */
