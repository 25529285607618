import { CLIENT_STORAGE_NAME } from "./constant";
import { SERVER_STORAGE } from "./server-storage";
import { ConfigPropertyName, ConfigStorage } from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setConfig(value: ConfigStorage): void {
  if (IS_SERVER || IS_TEST) {
    SERVER_STORAGE[ConfigPropertyName.Public] = value[ConfigPropertyName.Public];
    SERVER_STORAGE[ConfigPropertyName.Server] = value[ConfigPropertyName.Server];
  } else {
    // eslint-disable-next-line no-restricted-globals
    window[CLIENT_STORAGE_NAME][ConfigPropertyName.Public] =
      value[ConfigPropertyName.Public];
  }
}
