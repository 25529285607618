export const featureFlagPrefix = ".appconfig.featureflag";
export const webAppPrefix = "webApp";
export const clientSidePrefix = `client`;
export const serverSidePrefix = `server`;

export const featureFlagRegExp = new RegExp(`^${featureFlagPrefix}/`);
export const webAppRegExp = new RegExp(`^${webAppPrefix}/`);

export const DEFAULT_TTL = 60 * 1000;
export const AZURE_CONFIG_DEFAULT_LOCALE = "default";

export const LOGGER_CONTEXT = "azure-configuration";
