export const LAST_VISITED_CLP_PAGE = "LAST_VISITED_CLP_PAGE";
export const OPTANON_CONSENT_COOKIES = "OptanonConsent";
export const OPTANON_ALERT_BOX_CLOSED = "OptanonAlertBoxClosed";
export const PREFERRED_STORE = "PreferredStore";
export const LAST_SEARCHES = "LAST_SEARCHES";
export const INFO_BANNER_CLOSED = "infoBannerClosed";
export const SELECTED_STORE = "SelectedStore";
export const GEO_ALLOWED = "GeoAllowed";
export const DY_ID = "_dyid";
export const DY_ID_SERVER = "_dyid_server";
export const DY_SESSION = "_dyjsession";
export const ORDER_CONFIRMATION_SESSION = "order-confirmation-session";
export const PRIMARK_LOCALE = "primark-locale";
