import { LOCALES } from "~/constants/i18n";

import { AzureConfigurator } from "./azure-configurator";

export interface UseAzureConfigType {
  appConfig: ReturnType<typeof AzureConfigurator.getConfig>;
  featureFlags: ReturnType<typeof AzureConfigurator.getFeatureFlags>;
  locale?: string;
}

export const useAzureConfigurator = (locale: LOCALES): UseAzureConfigType => {
  return {
    appConfig: AzureConfigurator.getConfig(locale),
    featureFlags: AzureConfigurator.getFeatureFlags(locale),
    locale,
  };
};
