/* eslint no-process-env: 0 */

import { kebabCase } from "lodash";

import { EnvVars } from "~/constants/env-vars";
import { LOCALES } from "~/constants/i18n";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { getEnvironmentVariable } from "~/services/azure-configurator/helpers";

export const TEST_AUTOMATION_ID = "data-testautomation-id";

export interface TestAutomationProps {
  [TEST_AUTOMATION_ID]?: string;
}

export const getTestAutomationProps = (
  key: string | null,
  locale?: LOCALES,
): TestAutomationProps => {
  if (
    AzureConfigurator.getConfig(locale)?.technical?.testIdsAreEnabled === "true" ||
    getEnvironmentVariable(EnvVars.TEST_IDS_ENABLED)
  ) {
    return {
      [TEST_AUTOMATION_ID]: kebabCase(key ?? undefined),
    };
  }
  return {};
};
