import { LOCALES } from "~/constants/i18n";

export const getCountryCodeFromLocale = (locale: LOCALES): string => {
  return locale.split("-").pop() as string;
};

export const getCountryLangFromLocale = (locale: LOCALES): string => {
  return locale.split("-")[0];
};

export const getLocaleFromUrl = (url: string): string => {
  const localesArray = Object.values(LOCALES);
  for (const locale of localesArray) {
    const localeRegex = new RegExp(`(^|/|\\b)${locale}($|/|\\b|#)`);
    if (localeRegex.test(url)) {
      return locale;
    }
  }
  return "";
};

export const getLinkUrl = (locale: LOCALES, href = ""): string => {
  const isExternalLink = href?.includes("http");

  if (isExternalLink) {
    return href;
  }

  const hrefIncludesLocale = !!getLocaleFromUrl(href);

  const linkUrl = hrefIncludesLocale ? `/${href}` : `/${locale}/${href}`;

  return linkUrl.replace("//", "/");
};

export const getLocaleByContryAndLanguage = (
  languageCode: string,
  countryKey: string,
): LOCALES => `${languageCode}-${countryKey}`.toLowerCase() as LOCALES;
