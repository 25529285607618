import Cookies from "js-cookie";

import { OPTANON_CONSENT_COOKIES } from "~/constants/user-cookies";

const _getCategoriesGroupArray = (params: URLSearchParams): string[] => {
  const groups = params.get("groups");
  return groups ? groups.split(",") : [];
};

const _getCategoriesFromGroup = (groups: string[]): { [p: string]: boolean } => {
  if (!groups) {
    return {};
  }

  return groups.reduce((acc, group) => {
    const [key, value] = group.split(":");
    return { ...acc, [key]: !!+value };
  }, {});
};

export const getCookieCategories = (
  oneTrustCookieString?: string,
): { [p: string]: boolean } => {
  const convertedString = new URLSearchParams(oneTrustCookieString);
  const groups = _getCategoriesGroupArray(convertedString);

  return groups ? _getCategoriesFromGroup(groups) : {};
};

export const isCookiesGroupAccepted = (groupName: string): boolean => {
  const OptanonConsent = Cookies.get(OPTANON_CONSENT_COOKIES);
  if (OptanonConsent) {
    const cookieCategories = getCookieCategories(OptanonConsent);
    return cookieCategories[groupName];
  } else {
    return false;
  }
};

export const getParsedCookieCategories = () => {
  const OptanonConsent = Cookies.get(OPTANON_CONSENT_COOKIES);
  if (OptanonConsent) {
    const cookieCategories = getCookieCategories(OptanonConsent);
    return JSON.stringify(cookieCategories);
  } else {
    return "";
  }
};
