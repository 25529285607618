import { CLIENT_STORAGE_NAME } from "./constant";

declare global {
  interface Window {
    [CLIENT_STORAGE_NAME]: ConfigStorage;
  }
}

export enum ConfigPropertyName {
  Public = "publicRuntimeConfig",
  Server = "serverRuntimeConfig",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ConfigStorage<T = any> = Partial<Record<ConfigPropertyName, T>>;
