import { ConfigStorage } from "./types";

export const CLIENT_STORAGE_NAME = "PRIMARK_CONFIG_STORAGE";
export const CLIENT_STORAGE_CONTAINER_ELEMENT_NAME =
  "primark-config-storage-container";
export const PUBLIC_ENV_STORAGE_CONTAINER_ELEMENT_NAME =
  "primark-public-env-storage-container";
export const DEFAULT_STORAGE_VALUE: ConfigStorage = {};
export const LOGGER_CONTEXT = "config-storage";
export const PUBLIC_ENVS_PREFIX = "NEXT_PUBLIC_";
