/* eslint no-console: 0 */

import { isDebug } from "~/utils/is-debug";

import { Category } from "./types";

export class Logger {
  constructor(private category: Category = "default") {}
  trace(message: string, ...args: unknown[]): void {
    if (!IS_TEST && isDebug()) {
      console.trace(`[TRACE] ${this.category} ${message}`, ...args);
    }
  }
  debug(message: string, ...args: unknown[]): void {
    if (!IS_TEST && isDebug()) {
      console.debug(
        `%c[DEBUG] ${this.category} %c${message}`,
        "font-weight: bold;",
        "font-weight: normal;",
        ...args,
      );
    }
  }
  info(message: string, ...args: unknown[]): void {
    if (!IS_TEST && (isDebug() || IS_DEVELOPMENT)) {
      console.info(
        `%c[INFO] ${this.category} %c${message}`,
        "font-weight: bold;",
        "font-weight: normal;",
        ...args,
      );
    }
  }
  warn(message: string, ...args: unknown[]): void {
    if (!IS_TEST && (isDebug() || IS_DEVELOPMENT)) {
      console.warn(
        `%c[WARN] ${this.category} %c${message}`,
        "font-weight: bold;",
        "font-weight: normal;",
        ...args,
      );
    }
  }
  error(message: string, ...args: unknown[]): void {
    if (!IS_TEST) {
      console.error(
        `%c[ERROR] ${this.category} %c${message}`,
        "font-weight: bold;",
        "font-weight: normal;",
        ...args,
      );
    }
  }
  fatal(message: string, ...args: unknown[]): void {
    if (!IS_TEST) {
      console.error(
        `%c[FATAL] ${this.category} %c${message}`,
        "font-weight: bold;",
        "font-weight: normal;",
        ...args,
      );
    }
  }
  static getLogger(category: Category = "default"): Logger {
    return new this(category);
  }
}
