/* eslint no-process-env: 0 */

import { EnvVars } from "~/constants/env-vars";
import { PUBLIC_ENV_STORAGE_CONTAINER_ELEMENT_NAME } from "~/services/config-storage/constant";

import { getConfig } from "../config-storage/get-config";

import { AllPossibleConfigData } from "./types";

export const getEnvironmentVariable = (name: string): string | undefined => {
  const { publicRuntimeConfig = {}, serverRuntimeConfig = {} } = getConfig();

  if (!IS_SERVER && !IS_TEST && !process?.env?.IS_READY) {
    // eslint-disable-next-line no-restricted-globals
    const element = window.document.getElementById(
      PUBLIC_ENV_STORAGE_CONTAINER_ELEMENT_NAME,
    );
    const processEnv = element ? JSON.parse(element.textContent as string) : null;
    process.env = { ...process.env, ...processEnv, IS_READY: true };
  }
  const value =
    serverRuntimeConfig[name] || publicRuntimeConfig[name] || process.env[name];

  if (value === null || typeof value === "undefined") {
    return undefined;
  }

  return value;
};

export const getOverridesForLocalDevelopment = (): AllPossibleConfigData => ({
  frontEndBaseUrl: getEnvironmentVariable(
    EnvVars.NEXT_PUBLIC_LOCAL_FRONT_END_BASE_URL,
  ),
  apiBaseUrl: IS_SERVER
    ? getEnvironmentVariable(EnvVars.LOCAL_SERVER_SIDE_API_BASE_URL)
    : getEnvironmentVariable(EnvVars.NEXT_PUBLIC_LOCAL_CLIENT_API_BASE_URL),
  technical: {
    debug: getEnvironmentVariable(EnvVars.DEBUG),
    testIdsAreEnabled: getEnvironmentVariable(EnvVars.TEST_IDS_ENABLED),
    minifyJsIsEnabled: getEnvironmentVariable(EnvVars.MINIFY_JS_ENABLED),
    minifyCssIsEnabled: getEnvironmentVariable(EnvVars.MINIFY_CSS_ENABLED),
  },
});
