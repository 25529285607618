export enum EventNames {
  checkAvailability = "check availability",
  checkAvailabilityStatus = "check availability status",
  changeSize = "change size",
  renderOopsPage = "renderOopsPage",
  networkError = "GraphQL NetworkError",
  olapicWidget = "olapic widget preperation",
  evergageInit = "evergage init",
  evergageSnippetInit = "evergage snippet init",
  windowError = "WindowOnError",
}
