export enum RouteBase {
  SEARCH = "/search",
  STORES = "/stores",
  ATP = "/e",
  ARTICLE = "/a",
  PDP = "/p",
  CLP_PLP = "/c",
  RBP = "/r",
  VISUALIZATION = "/visualization",
  ERROR = "/_error",
  PROFILE = "/profile",
  LOGOUT = "/auth/logout",
  LOGIN = "/auth/login",
  SHOPPING_LIST = "/shopping-list",
  SHOPPING_BAG = "/shopping-bag",
  CHECKOUT = "/checkout",
  BLANK = "/blank.html",
  ORDER_HISTORY_AND_DETAILS = "/profile/orders", // was renamed as duplicate for ORDER_DETAILS
  PAYMENT_CARDS = "/profile/payment-cards",
  BILLING_ADDRESSES = "/profile/billing-addresses",
  // ORDER_DETAILS = "/profile/orders", TODO check, this was planned for en-gb/order-details
  THANKS_FOR_REGISTERING = "/thanks-for-registering",
  C_PLUS_C_LANDING_PAGE = "/landing-page",
  PDSP_LANDING_PAGE = "/informations-produit",
}

export const Routes = {
  SEARCH: RouteBase.SEARCH,
  STORES_SEARCH: RouteBase.STORES,
  STORES_CITY: `${RouteBase.STORES}/[city]`,
  STORES_STORE: `${RouteBase.STORES}/[city]/[address]`,
  HOME: "/",
  ATP: `${RouteBase.ATP}/[slug]`,
  ARTICLE_PAGE: `${RouteBase.ARTICLE}/[slug]`,
  PDP_PAGE: `${RouteBase.PDP}/[slug]`,
  CLP_PLP: `${RouteBase.CLP_PLP}/[...slug]`,
  RBP: `${RouteBase.RBP}/[...slug]`,
  VISUALIZATION: RouteBase.VISUALIZATION,
  ERROR: RouteBase.ERROR,
  PROFILE: RouteBase.PROFILE,
  LOGOUT: RouteBase.LOGOUT,
  LOGIN: RouteBase.LOGIN,
  SHOPPING_LIST: RouteBase.SHOPPING_LIST,
  SHOPPING_BAG: RouteBase.SHOPPING_BAG,
  CHECKOUT: RouteBase.CHECKOUT,
  BLANK: RouteBase.BLANK,

  ORDER_HISTORY: RouteBase.ORDER_HISTORY_AND_DETAILS,
  //ORDER_DETAILS: RouteBase.ORDER_DETAILS, TODO check, this was planned for en-gb/order-details (was duplicate for RouteBase.ORDER_HISTORY)
  PAYMENT_CARDS: RouteBase.PAYMENT_CARDS,
  BILLING_ADDRESSES: RouteBase.BILLING_ADDRESSES,
  GCP_PAGE: "/[...slug]",
  THANKS_FOR_REGISTERING: RouteBase.THANKS_FOR_REGISTERING,
  C_PLUS_C_LANDING_PAGE: RouteBase.C_PLUS_C_LANDING_PAGE,
  PDSP_LANDING_PAGE: RouteBase.PDSP_LANDING_PAGE,
};

export enum Params {
  QUERY = "q",
  STORE_QUERY = "storeQuery",
  COUNTRY = "country",
  CITY = "city",
  ADDRESS = "address",
  LATITUDE = "lat",
  LONGITUDE = "lng",
  ZOOM = "zoom",
  SLUG = "slug",
  TAB = "tab",
  ID = "id",
  CONTENT_ID = "contentId",
  LOCALE = "locale",
  CURRENCY_CODE = "currencyCode",
  VSE = "vse",
  STORE = "store",
  ORDER_ID = "order-id",
  PAGE = "page",
  RESET_PASSWORD_POPUP = "resetPasswordPopup",
  CHANGE_PASSWORD_POPUP = "changePasswordPopup",
  SESSION_EXPIRED_POPUP = "sessionExpiredPopup",
  REDIRECT_TO = "redirectTo",
  REDIRECT_FROM = "redirectFrom",
  DY_API_PREVIEW_IDS = "dyApiPreview",
}

export enum Variables {
  SLUG = "slug",
  COUNTRY = "country",
  PAGE_URL = "pageUrl",
  PAGE_SLUG = "pageSlug",
  BREADCRUMBS = "breadcrumbs",
  URL_PREFIX = "urlPrefix",
  PRODUCT = "product",
  PRODUCT_CODE = "productCode",
  COLOR_PALETTE = "colorPalette",
  BLOOMREACH_DATA = "bloomreachData",
  EVERGAGE_CATEGORIES = "evergageCategories",
  COUNTRY_SELECTOR_LINK = "countrySelectorLink",
  SHOPPING_LIST_BY_ID = "shoppingListById",
  SHOPPING_LIST_ID = "shoppingListId",
  PROFILE_PAGE_SELECTION = "profilePage",
  MINIMUM_ORDER_PRICE = "minimumOrderPrice",
  TRANSLATIONS = "translations",
  PRODUCT_TAGS = "productTags",
  VISUALIZATION = "visualization",
  ORDER_DETAILS_REF = "orderDetailsRef",
  FACET_LIST = "facetList",
  PASSWORD_RESET_SUCCESS_POP_UP = "passwordResetSuccessPopUp",
  FILTERS_MENU = "filtersMenu",
  HIDE_TOP_SPACING_ON_GCP = "hideTopSpacingOnGcp",
  SIMILAR_ITEMS_GA_DATA = "similarItemsGAData",
  COUNTRY_NAMES = "countryNames",
  COUNTRY_NAME = "countryName",
  PAGE_TYPE = "pageType",
  PAGE_TITLE = "pageTitle",
  LOGO_IMAGE_URL = "logoImageUrl",
}

export enum PageType {
  PDP = "PDP",
}

export enum Headers {
  PAGE_LOCALE = "x-page-locale",
}
